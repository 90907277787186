<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">遗产要素单体局部影像图监测数据</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
                @submit.native.prevent
            >
                <el-form-item
                    label="遗产要素"
                    prop="YCYSBM"
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.YCYSBM"
                        :disabled="!isEdit"
                        placeholder="请选择遗产要素"
                        filterable
                        @change="getYcysDtList()"
                    >
                        <el-option
                            v-for="(item, index) in ycysList"
                            :key="index"
                            :label="item.MC"
                            :value="item.BM"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="遗产要素单体"
                    prop="YCYSDTHJBTPID"
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.YCYSDTHJBTPID"
                        :disabled="!isEdit"
                        placeholder="请选择遗产要素单体"
                        filterable
                    >
                        <el-option
                            v-show="!formdata.dataDic.YCYSBM"
                            value="请先选择遗产要素"
                            label="请先选择遗产要素"
                            disabled
                        ></el-option>
                        <el-option
                            v-show="formdata.dataDic.YCYSBM"
                            v-for="{ Id, Mc } in ycysDtList"
                            :key="Id"
                            :label="Mc"
                            :value="Id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="遗产要素保存状态"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.YCYSBCZT"
                        :disabled="!isEdit"
                        :clearable="true"
                        placeholder="请选择遗产要素保存状态"
                        @change="changeYcysbczt()"
                    >
                        <el-option
                            v-for="{ CODE, LABEL } in enumObj.YCYSBCZT"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="引起变化的原因"
                    v-if="formdata.dataDic.YCYSBCZT != '0'"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="yqbhyyValue"
                        @change="changeYQBHYY"
                        multiple
                        :disabled="!isEdit"
                        placeholder="请选择引起变化的原因"
                    >
                        <el-option
                            v-for="{ CODE, LABEL } in enumObj.YQBHYY"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="影响评估"
                    v-if="formdata.dataDic.YCYSBCZT != '0'"
                    prop="YXPG"
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.YXPG"
                        :disabled="!isEdit"
                        placeholder="请选择评估"
                    >
                        <el-option
                            v-for="{ CODE, LABEL } in enumObj.YXPG"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="情况说明"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.QKSM"
                        :disabled="!isEdit"
                        placeholder="请填写情况说明"
                        type="textarea"
                        :autosize="{ minRows: 3 }"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="照片" prop="ZP" :style="{ width: '80%' }">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="formdata.itemCode"
                        :metaData.sync="formdata.metaJson"
                        basicType="picture"
                        @handleChange="handleChange"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";
export default {
    mixins: [infoMixin],
    data() {
        const validatPic = (rule, value, cb) => {
            if (!this.formdata.fileids) {
                cb(new Error("没有传图片"));
            } else {
                cb();
            }
        };
        return {
            showPickup: false,
            isEdit: false,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    YCYSBM: "",
                    YCYSDTHJBTPID: "",
                    YCYSBCZT: "",
                    YQBHYY: "",
                    YXPG: "",
                    QKSM: "",
                },
                fileids: "",
                itemCode: "602",
                metaJson: [],
            },
            rules: {
                YCYSBM: [
                    {
                        required: true,
                        message: "请选择遗产要素",
                        trigger: "change",
                    },
                ],
                YCYSDTHJBTPID: [
                    {
                        required: true,
                        message: "请选择遗产要素单体",
                        trigger: "change",
                    },
                ],
                YCYSBCZT: [
                    {
                        required: true,
                        message: "请选择遗产要素保存状态",
                        trigger: "change",
                    },
                ],
                YXPG: [
                    {
                        required: true,
                        message: "请选择评估",
                        trigger: "change",
                    },
                ],
                ZP: [{ validator: validatPic, required: true }],
            },
            ycysList: [], // 遗产要素列表
            ycysDtList: [], // 遗产要素单体列表
            yqbhyyValue: [], // 引起变化原因
        };
    },
    watch: {
        fileidsValue() {
            this.$refs.formdata.validateField(["ZP"]);
        },
    },
    computed: {
        fileidsValue() {
            return this.formdata.fileids;
        },
    },
    mounted() {
        this.getYcysList();
    },
    methods: {
        ...mapActions(["GetYcysList", "getYcysdtData"]),
        // 获取遗产要素单体
        async getYcysDtList() {
            let result = await this.getYcysdtData({
                ycysbm: this.formdata.dataDic.YCYSBM,
            });
            if (result.IsSuccess) {
                this.ycysDtList = result.ResultValue;
            }
        },
        // 获取遗产要素
        async getYcysList() {
            const res = await this.GetYcysList();
            if (res.IsSuccess) {
                this.ycysList = res.ResultValue;
            }
        },
        GetDataNext() {
            if (this.formdata.dataDic) {
                if (this.formdata.dataDic.YCYSBM) {
                    // 修改
                    this.getYcysDtList();
                }
                if (
                    this.formdata.dataDic.YXPG ||
                    this.formdata.dataDic.YXPG === 0
                ) {
                    this.formdata.dataDic.YXPG =
                        this.formdata.dataDic.YXPG + "";
                }
                if (
                    this.formdata.dataDic.YCYSBCZT ||
                    this.formdata.dataDic.YCYSBCZT === 0
                ) {
                    this.formdata.dataDic.YCYSBCZT =
                        this.formdata.dataDic.YCYSBCZT + "";
                }
                if (this.formdata.dataDic.YQBHYY) {
                    this.yqbhyyValue = this.formdata.dataDic.YQBHYY.split(",");
                }
            }
        },
        // 修改引起变化原因 数组 转 字符串
        changeYQBHYY() {
            this.formdata.dataDic.YQBHYY = this.yqbhyyValue.join(",");
        },
        // 遗产要素保存状态更改 清空数据
        changeYcysbczt() {
            if (this.formdata.dataDic.YCYSBCZT == "0") {
                this.yqbhyyValue = [];
                this.formdata.dataDic.YQBHYY = "";
                this.formdata.dataDic.YXPG = "";
            }
        },
    },
};
</script>

<style></style>
